
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import * as Sentry from "@sentry/react";
import VideoPage from './pages/video'

const { REACT_APP_ENVIRONMENT } = process.env;

Sentry.init({
  environment: REACT_APP_ENVIRONMENT,
  dsn: "https://d4f4e33d9fcc9c8d201f1eb73958bb40@o4506543658827776.ingest.sentry.io/4506686081531904",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/patch-education-frontend\.onrender\.com\//],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: REACT_APP_ENVIRONMENT == 'production'
});

function App() {

  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, []);

  return (
    <BrowserRouter>
        <Routes>
          <Route 
            path="/:dyteId"
            element={<VideoPage />}
          />
        </Routes>
      </BrowserRouter>
  );
}

export default App;
