import { useCallback, useEffect, useState } from 'react';
import { useDyteClient } from '@dytesdk/react-web-core';
import { DyteMeeting, registerAddons } from '@dytesdk/react-ui-kit';
import { useParams } from "react-router-dom"
import PatchIconLogoWhite from 'assets/patch-icon-logo-white.png'
import CustomButton from "@dytesdk/ui-kit-addons/custom-controlbar-button";
const { REACT_APP_ENVIRONMENT } = process.env;

const VideoCall = () => {
  const { dyteId } = useParams()
  const [meeting, initMeeting] = useDyteClient();
  const [expired, setExpired] = useState(false)

  console.log('dyteId', dyteId)
  console.log('TEST', dyteId)

  useEffect(() => {
    if (dyteId) {

      const url = REACT_APP_ENVIRONMENT == 'development' ? `https://7760-67-173-226-63.ngrok-free.app/video/${dyteId}` : `https://transcription-backend.onrender.com/video/${dyteId}`

      fetch(url, { headers: { 'ngrok-skip-browser-warning': 'skip-browser-warning' }, redirect: "follow", mode: "cors", credentials: "omit", cache: "no-cache" })
        .then((response) => {
          // console.log('response', response)
          // console.log('response json', response.text())
          return response.json()
        }).then((responseJson) => {
          console.log('token', responseJson.token)
          setExpired(responseJson.expired)
          if (!responseJson.expired) {
            initMeeting({
              authToken: responseJson.token,
              defaults: {
                audio: true,
                video: true,
              },
            });
          }
        })
    }
  }, [dyteId]);

  const switchCamera = useCallback(async () => {
    if (!meeting) return;

    const devices = await meeting.self.getVideoDevices();
    if (devices.length <= 1) return;

    const currentDevice = meeting.self.getCurrentDevices().video;

    let nextDevice;
    if (devices.some(device => device.label.toLowerCase().includes('front'))) {
      // Separate front and back cameras
      const frontCameras = devices.filter(device => device.label.toLowerCase().includes('front'));
      const backCameras = devices.filter(device => device.label.toLowerCase().includes('back'));

      // check if current camera is front camera of back camera
      const isFrontCamera = frontCameras.some(device => device.deviceId === currentDevice?.deviceId);

      if (isFrontCamera) {
        nextDevice = backCameras[0];
      } else {
        nextDevice = frontCameras[0];
      }
    } else {
      const currentIndex = devices.findIndex((device) => device.deviceId === currentDevice?.deviceId) || 0;
      nextDevice = devices[(currentIndex + 1) % devices.length];
    }

    await meeting.self.setDevice(nextDevice);
  }, [meeting]);


  if (expired) {
    return (
      <div className='bg-slate-800 h-screen w-full flex flex-col items-center justify-center'>
        <img className="mx-auto h-16 w-auto" src={PatchIconLogoWhite} alt="Patch Education" />
        <p className='text-white mt-8'>Link Is Expired</p>
      </div>
    )
  }


  if (!meeting) {
    return (
      <div className='bg-slate-800 h-screen w-full flex flex-col items-center justify-center'>
        <img className="mx-auto h-16 w-auto" src={PatchIconLogoWhite} alt="Patch Education" />
        <p className='text-white mt-8'>Loading Video Call</p>
      </div>
    )
  }

  const toggleCameraButton = new CustomButton({
    position: 'center',
    label: 'Toggle Camera',
    onClick: switchCamera,
    icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" /></svg>'
  });

  const dyteMeetingConfig = registerAddons([toggleCameraButton], meeting);

  if (meeting) {
    return <DyteMeeting meeting={meeting} config={dyteMeetingConfig} />
  }

}

export default VideoCall
